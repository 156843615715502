<template>
  <div class="contentBox audienceDefinition">
    <div class="row">
      <div class="col-md-5">
        <h2>Audience Definition</h2>
        <ul>
          <li>
            Location:
            {{ estimates.location }}
          </li>
          <li>Age: {{ campaign.ageStart }}-{{ campaign.ageEnd }}+</li>
          <li>Potential Reach:</li>
        </ul>
        <h2>{{ estimates.potential }} people</h2>
      </div>
      <div class="col-md-7">
        <!-- Chart -->
        <chart />
      </div>
    </div>
    <!-- Statistics -->
    <statics :campaignEstimate="estimates" />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    campaign: Object,
  },
  computed: {
    ...mapState({
      estimates: (state) => state.campaign.estimates,
    }),
  },
  components: {
    Statics: () =>
      import(
        /* webpackChunkName: "statics" */ "@/components/brand/campaigns/new/partials/Statics.vue"
      ),
    Chart: () =>
      import(
        /* webpackChunkName: "statics" */ "@/components/brand/campaigns/new/partials/Chart.vue"
      ),
  },
};
</script>

<style lang="scss" scoped>
.audienceDefinition {
  padding: rem(35px);
  h2 {
    font-size: rem(20px);
    font-weight: 700;
    margin: rem(17px) 0;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      font-size: rem(15px);
      color: #494e5a;
      line-height: 1;
      &:not(:last-child) {
        margin-bottom: rem(12px);
      }
    }
  }
  .image {
    margin-top: -25px;
    @media screen and (max-width: 767px) {
      margin: 20px auto;
      text-align: center;
    }
  }
}
</style>
